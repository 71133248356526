
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body {
  font-family: "Roboto", sans-serif;
}

.head-title {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: bold;
}
.svg {
  color : "red";
}

.overflow-hidden {
    overflow: hidden;
  }

  
.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 0!important;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2) !important;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #sidebar {
    -webkit-transition: all 300ms cubic-bezier(0, 0.77, 0.58, 1);
    transition: all 300ms cubic-bezier(0, 0.77, 0.58, 1);
}

#sidebar.flex {
    transform: translateX(0);
}

#sidebar ul li a.active {
    background: #1f2937;
    background-color: #1f2937;
}



// =========================================
// font size
.text-header {
  @apply font-bold text-2xl tracking-wide;

  @media (min-width: 768px) {
    @apply text-3xl tracking-wider;
  }

  @media (min-width: 1024px) {
    @apply text-4xl tracking-wider;
  }

  @media (min-width: 1280px) { 
    @apply text-4xl tracking-wider;
   }
}


.text-body {
  @apply text-base tracking-wide;

  @media (min-width: 768px) {
    @apply text-lg tracking-wider;
  }
}


.text-title {
  @apply text-lg ;

  @media (min-width: 768px) {
    @apply text-xl ;
  }

  @media (min-width: 1024px) {
    @apply text-[28px] p-1 ;
  }
}

.text-desc {
  @apply text-sm tracking-wide ;

  @media (min-width: 768px) {
    @apply text-sm tracking-wider;
  }

  @media (min-width: 1024px) {
    @apply text-[16px] tracking-wider;
  }

  @media (min-width: 1280px) { 
    @apply text-[16px] tracking-wider;
   }
}

.text-info {
  @apply  text-lg font-medium;

  @media (min-width: 768px) {
    @apply  text-xl font-medium ;
  }

  @media (min-width: 1024px) {
    @apply   text-2xl font-medium;
  }
}



